import styled from 'styled-components';
import {Outlet} from 'react-router-dom';
import Header from './header';
import Alert from '../alert';

function Layout({user, alert}) {
	return (
		<div>
			<Header user={user} />
			<Content>
				<Outlet />
			</Content>
			{alert && <Alert alert={alert} />}
		</div>
	);
}

export default Layout;

const Content = styled.div`
	margin: auto;
	width: 100vw;
	height: 100vh;
	max-width: 640px;
	padding-top: 50px;
`;
