import PropTypes from 'prop-types';
import styled from 'styled-components';
import {useState} from 'react';
import {DB_KEY} from 'fbase';
import {MdComment, MdShare, MdBookmarkBorder, MdBookmark} from 'react-icons/md';
import Comments from './comments';
import Button from './button';
import ProfileImg from './profileimg';
import {ALERTS, Colors, Depth} from 'constant';
import {Flex} from './layout';
import {api} from 'apis';

function Issue({user, issue, mypick, spreadComments, fireAlert, refreshIssue}) {
	const [pick, setPick] = useState(null);
	const [isCommentActive, setCommentActive] = useState(spreadComments ? spreadComments : false);
	const [isCommentVisible, setCommentVisible] = useState(spreadComments ? spreadComments : false);
	const isVoted = mypick !== null ? true : false;
	const vote_sum = Object.values(issue.choices).reduce((acc, cur) => acc + cur.vote, 0);
	const bookmarked = user && issue[DB_KEY.ISSUE.BOOKMARK] && issue[DB_KEY.ISSUE.BOOKMARK].includes(user.id);
	const isAuthor = user ? issue.author === user.id : false;

	const getPercent = (idx) => {
		if (vote_sum === 0) return 0;
		const percent = (issue.choices[idx].vote / vote_sum) * 100;
		return Math.floor(percent);
	};

	const handleCommentVisibility = () => {
		if (!isCommentActive) {
			setCommentActive(true);
			setCommentVisible(true);
		} else {
			setCommentVisible(!isCommentVisible);
		}
		// Active 할 때마다 데이터 새로 받지 않기 위해서
		// 한번 댓글을 연 뒤로는 Style만 수정해서 보이기/숨기기
	};

	const onShare = () => {
		try {
			const link = `${window.location.host}/issue/${issue.id}`;
			navigator.clipboard.writeText(link);
			fireAlert('클립보드에 복사되었습니다!', ALERTS.INFO);
		} catch (e) {
			fireAlert(`알 수 없는 문제가 발생했어요: ${e}`, ALERTS.ERROR);
		}
	};

	const handleToggleBookmark = async () => {
		if (!user) {
			fireAlert('로그인 후에 사용할 수 있습니다.', ALERTS.ERROR);
			return;
		}
		await api.issue.bookmark(user, issue);
		refreshIssue(issue.id);
	};

	const onVoteClick = (p) => {
		if (isVoted) return;
		if (pick === p) {
			setPick(null);
			return;
		}
		setPick(p);
	};

	const handleVoteConfirm = async () => {
		if (!user) {
			fireAlert('로그인 후에 사용할 수 있습니다.', ALERTS.ERROR);
			return;
		}
		try {
			await api.issue.vote(user, issue, pick);
			setPick(null);
			refreshIssue(issue.id);
		} catch (e) {
			console.error('[failed vote conform]', e);
		}
	};

	return (
		<Container>
			<Question>
				<VoteBox.Wrapper>
					<VoteBox.Bottom />
					<VoteBox.Text>{vote_sum}</VoteBox.Text>
				</VoteBox.Wrapper>
				{issue.question}
			</Question>
			<Choices>
				{issue.choices &&
					Object.entries(issue.choices).map((c) => {
						const [idx, data] = c;
						return (
							<Choice onClick={() => onVoteClick(idx)} key={idx} space='between' style={{border: pick && pick === idx ? `1px solid ${Colors.highlight}` : 'none'}}>
								{isVoted && <Gauge percent={getPercent(idx)} isMyPick={idx === mypick} />}
								<Flex space='between' style={{zIndex: Depth.issueChoice, width: '100%'}}>
									<div>{data.text}</div>
									{isVoted && <div>{getPercent(idx)}%</div>}
								</Flex>
							</Choice>
						);
					})}
				{pick && (
					<Button onClick={handleVoteConfirm} full primary pill style={{marginBottom: '1em'}}>
						{issue.choices[pick].text} 에 투표하기
					</Button>
				)}
			</Choices>
			<IssueFooter>
				{isAuthor && <ProfileImg user={user} imgurl={user.photoURL} width='1.5rem' height='1.5rem' style={{marginRight: '0.4rem'}} />}
				<StyledIconBtn onClick={handleToggleBookmark}>
					{bookmarked ? <MdBookmark style={{fontSize: '1.3rem', color: Colors.warning}} /> : <MdBookmarkBorder style={{fontSize: '1.3rem'}} />}
					{/* <span>저장</span> */}
				</StyledIconBtn>
				<StyledIconBtn onClick={onShare}>
					<MdShare style={{fontSize: '1.3rem'}} />
					{/* <span>공유</span> */}
				</StyledIconBtn>
				<StyledIconBtn active={isCommentVisible} onClick={handleCommentVisibility}>
					<MdComment style={{fontSize: '1.3rem', marginRight: 5}} />
					<span>{issue.comments_count ? issue.comments_count : 0}</span>
				</StyledIconBtn>
			</IssueFooter>
			{isCommentActive && <Comments user={user} issue={issue} refreshIssue={refreshIssue} style={{display: isCommentVisible ? 'block' : 'none'}} />}
		</Container>
	);
}

const VoteBox = {
	Wrapper: styled.div`
		position: relative;
		display: inline-block;
		margin-right: 1.1rem;
		bottom: 5px;
		left: 5px;
	`,

	Bottom: styled.div`
		position: absolute;
		height: 0.8rem;
		/* background-color: red; */
		right: -0.2em;
		left: -0.2em;
		margin: auto;
		bottom: -0.3rem;
		background-color: black;
		border-radius: 0.2em;
	`,
	Text: styled.div`
		border: 2px solid black;
		padding: 0.1rem 0.3rem;
		background-color: white;
		position: relative;
		border-radius: 0.2rem 0.2rem 0 0;
		color: black;
		font-weight: bold;
		font-size: 0.8rem;
	`
};

const Container = styled.div`
	background-color: white;
	padding: 0.5em;
	margin-bottom: 20px;
	box-shadow: 0px 5px 7px #e9e9e9;
`;

const Question = styled.div`
	padding: 1em 0.5em;
	margin-bottom: 0.5em;
	font-size: 1.1rem;
`;

const Choices = styled.div`
	padding: 0px 0.5em;
`;

const Choice = styled.div`
	position: relative;
	display: flex;
	border-radius: 0.3rem;
	padding: 0.9em;
	margin-bottom: 0.5em;
	overflow: hidden;
	background-color: #eae9ef;
`;

const Gauge = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: ${(props) => (props.percent ? props.percent : 0)}%;
	background-color: ${(props) => (props.isMyPick ? `#c9c9ff` : `#cacad5`)};
`;

const IssueFooter = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 0.5em;
	font-size: 0.9em;
`;

const StyledIconBtn = styled.button`
	border: none;
	background: none;
	font-size: 1.1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 0.6rem;
	color: ${(props) => (props.active ? Colors.highlight : '#888895')};
`;

export default Issue;

Issue.propTypes = {
	user: PropTypes.object,
	issue: PropTypes.object.isRequired,
	mypick: PropTypes.string,
	spreadComments: PropTypes.bool,
	fireAlert: PropTypes.func,
	refreshIssue: PropTypes.func.isRequired
};
