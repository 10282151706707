import {useState} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {KnockLogo} from 'constant/svg';
import {Colors, Depth} from 'constant';
import {auth} from 'fbase';
import {signOut} from 'firebase/auth';
import {useNavigate} from 'react-router-dom';
import {ProfileImg} from 'component';

function Header({user}) {
	const navigate = useNavigate();

	const onLogoClick = () => {
		navigate('/');
	};

	const onLogout = () => {
		signOut(auth)
			.then(() => {
				// Sign-out successful.
			})
			.catch((error) => {
				console.error(error);
			});
	};
	return (
		<StyledHeader>
			<HeaderWrapper>
				<KnockLogo onClick={onLogoClick} style={{width: 93, height: 35, fill: Colors.highlight}} />
				{user ? <UserIcon user={user} onLogout={onLogout} /> : <Link to='/login'>Log in</Link>}
			</HeaderWrapper>
		</StyledHeader>
	);
}

function UserIcon({user, onLogout}) {
	const [isClicked, setIsClicked] = useState(false);

	const toggleProfileMenu = (e) => {
		setIsClicked(!isClicked);
		e.stopPropagation();
	};

	return (
		<Container>
			<ProfileImg user={user} onClick={toggleProfileMenu} imgurl={user.photoURL} />

			{isClicked && <Background onClick={toggleProfileMenu} />}
			{isClicked && (
				<StyledProfileMenu>
					<StyledProfileMenuItem>{user.displayName ? user.displayName : user.email}</StyledProfileMenuItem>
					<StyledProfileMenuItem>
						<Link to={`/profile/${user.id}`} onClick={toggleProfileMenu}>
							Profile
						</Link>
					</StyledProfileMenuItem>
					<StyledProfileMenuItem onClick={onLogout} color={Colors.warning}>
						Log Out
					</StyledProfileMenuItem>
				</StyledProfileMenu>
			)}
		</Container>
	);
}
export default Header;

const Background = styled.div`
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	// background-color: rgba(0, 0, 0, 0.5);
	z-index: ${Depth.profileMenu};
`;

const StyledHeader = styled.div`
	width: 100vw;
	position: fixed;
	top: 0;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.5em;
	background-color: ${Colors.background};
	z-index: ${Depth.header};
`;

const HeaderWrapper = styled.div`
	width: 100%;
	max-width: 840px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Container = styled.div`
	position: relative;
`;

const StyledProfileMenu = styled.div`
	z-index: ${Depth.profileMenu};
	position: absolute;
	top: 2rem;
	right: 0;
	background-color: white;
	box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
	padding: 0.5em;
	border-radius: 0.5rem;
	min-width: 150px;
	text-align: right;
	font-size: 0.8rem;
`;

const StyledProfileMenuItem = styled.div`
	padding: 0.5rem 0.3em;
	color: ${(props) => (props.color ? props.color : 'inherit')};
`;
