import {auth} from 'fbase';
import {useState, useEffect} from 'react';
import {LoginPage, Issues, IssueDetail, ProfilePage, NotFoundPage} from 'pages';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import {onAuthStateChanged} from 'firebase/auth';
import {onSnapshot, doc} from 'firebase/firestore';
import {db, DB_KEY} from 'fbase';
import {Layout} from 'component/layout';
import {Helmet} from 'react-helmet';
function App() {
	const [myvotes, setMyvotes] = useState({});
	const [user, setUser] = useState(null);
	const [alert, setAlert] = useState(null);

	const UserFromFirebase = (firebaseUser) => {
		return {
			id: firebaseUser.uid,
			email: firebaseUser.email,
			displayName: firebaseUser.displayName ? firebaseUser.displayName : firebaseUser.email.split('@')[0],
			photoURL: firebaseUser.photoURL
		};
	};

	useEffect(() => {
		onAuthStateChanged(auth, (u) => {
			console.debug('[onAuthStateChanged]');
			if (u) {
				setUser(UserFromFirebase(u));
			} else {
				// user is signed out
				setUser(null);
			}
		});
	}, []);

	const refreshUser = (obj) => {
		if (!obj) setUser(UserFromFirebase(auth.currentUser));
		else if (obj.photoURL) setUser({...user, photoURL: obj.photoURL});
	};

	useEffect(() => {
		if (!user) return;
		console.debug('[onSnapshot]:', `db.user-vote.${user.id}`);
		const unsub = onSnapshot(doc(db, DB_KEY.DB.USERVOTE, user.id), (doc) => setMyvotes(doc.data()));
		return () => unsub();
	}, [user]);

	const fireAlert = (msg, type) => {
		setAlert({
			message: msg,
			type: type
		});
		setTimeout(destroyAlert, 3000);
	};

	const destroyAlert = () => {
		setAlert(null);
	};

	return (
		<>
			<Helmet></Helmet>
			<Router>
				<Routes>
					<Route path='/' element={<Layout user={user} alert={alert} />}>
						<Route index element={<Issues user={user} fireAlert={fireAlert} myvotes={myvotes} />} />
						<Route path='issue/:issueId' element={<IssueDetail user={user} fireAlert={fireAlert} />} />
						<Route path='profile/:uid/*' element={user ? <ProfilePage user={user} alert={alert} fireAlert={fireAlert} myvotes={myvotes} refreshUser={refreshUser} /> : <LoginPage />} />
						<Route path='login' element={user ? <Navigate to='/' /> : <LoginPage fireAlert={fireAlert} />} />
						{/* <Route path='signup' element={user ? <Navigate to='/' /> : <SignUpPage fireAlert={fireAlert} />} /> */}
						<Route path={'*'} element={<NotFoundPage />} />
					</Route>
				</Routes>
			</Router>
		</>
	);
}

export default App;
