import styled from 'styled-components';
import {CryingKnock} from 'constant/svg';
import {Colors} from 'constant';

function Error({message}) {
	return (
		<Container>
			<Logo>
				<CryingKnock style={{fill: Colors.highlight}} />
			</Logo>
			<h2>에러 발생!</h2>
			<Paragraph>{`아이고.. 죄송해요. 문제가 생긴 것 같아요. 
      \n대충 ${message || '"어.. 뭐가 문제인지 잘 모르겠습니다"'} 라고 하는 것 같아요. \n개발자를 닥달해 보시는 건 어떨까요? \n이메일: zzoi.zisoo@gmail.com`}</Paragraph>
		</Container>
	);
}

export default Error;

const Logo = styled.div`
	width: 50%;
	max-width: 320px;
`;

const Container = styled.div`
	height: 80vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: ${Colors.deemed};
`;

const Paragraph = styled.div`
	text-align: center;
	color: grey;
	white-space: break-spaces;
`;
