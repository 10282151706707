function Row(props) {
	const styles = {
		container: {
			display: 'flex',
			justifyContent: props.space ? `space-${props.space}` : 'center',
			alignItems: props.align ? props.align : 'center',
			...props.style
		}
	};

	return (
		<div onClick={props.onClick ? props.onClick : null} style={styles.container}>
			{props.children}
		</div>
	);
}

export default Row;
