import {query, collection, orderBy, getDocs, limit, startAfter, doc, getDoc, addDoc, where, runTransaction} from 'firebase/firestore';
import {ref, getDownloadURL} from 'firebase/storage';
import {db, storage, DB_KEY, PAGINATION_COUNT} from 'fbase';

const QUERIES = {
	ISSUES: {
		FROM_START: () => query(collection(db, DB_KEY.DB.ISSUES), orderBy('created_at', 'desc'), limit(PAGINATION_COUNT)),
		START_AFTER: (start) => query(collection(db, DB_KEY.DB.ISSUES), orderBy('created_at', 'desc'), startAfter(start), limit(PAGINATION_COUNT)),
		USER: {
			FROM_START: (uid) => query(collection(db, DB_KEY.DB.ISSUES), where('author', '==', uid), orderBy('created_at', 'desc'), limit(PAGINATION_COUNT)),
			START_AFTER: (uid, start) => query(collection(db, DB_KEY.DB.ISSUES), where('author', '==', uid), orderBy('created_at', 'desc'), startAfter(start), limit(PAGINATION_COUNT))
		},
		BOOKMARKED: {
			FROM_START: (uid) => query(collection(db, DB_KEY.DB.ISSUES), where(DB_KEY.ISSUE.BOOKMARK, 'array-contains', uid), orderBy('created_at', 'desc'), limit(PAGINATION_COUNT)),
			START_AFTER: (uid, start) =>
				query(collection(db, DB_KEY.DB.ISSUES), where(DB_KEY.ISSUE.BOOKMARK, 'array-contains', uid), orderBy('created_at', 'desc'), startAfter(start), limit(PAGINATION_COUNT))
		}
	},
	COMMENTS: (issue_id) => collection(db, DB_KEY.DB.ISSUES, issue_id, 'comments')
};

const api = {
	issue: {
		new: async (data) => await addDoc(collection(db, DB_KEY.DB.ISSUES), data),
		get: (id) => fetchIssueDoc(id),
		vote: (user, issue, pick) => Vote(user, issue, pick),
		bookmark: (user, issue) => toggleBookmark(user, issue)
	},
	issues: {
		all: (last_item) => fetchIssues('all', null, last_item),
		author: (uid, last_item) => fetchIssues('author', uid, last_item),
		bookmark: (uid, last_item) => fetchIssues('bookmark', uid, last_item)
	},
	comment: {
		new: (issue_id, user_id, data) => newComment(issue_id, user_id, data)
	},
	comments: (issue_id) => fetchComments(issue_id),
	profile: {
		image_download_url: (url) => getProfileDownloadURL(url)
	}
};

const fetchIssueDoc = async (id) => {
	const ref = doc(db, DB_KEY.DB.ISSUES, id);
	console.debug('[doc]', ref.path);
	const issue = await getDoc(ref);
	if (issue.exists()) {
		const res = {id: issue.id, ...issue.data()};
		return res;
	} else {
		throw new Error(`issue ${id} is not exist`);
	}
};

const fetchIssues = (flag, uid, last_item) => {
	return new Promise((resolve, reject) => {
		let q;
		switch (flag) {
			case 'all':
				q = last_item ? QUERIES.ISSUES.START_AFTER(last_item) : QUERIES.ISSUES.FROM_START();
				break;
			case 'author':
				if (!uid) reject('no uid received');
				q = last_item ? QUERIES.ISSUES.USER.START_AFTER(uid, last_item) : QUERIES.ISSUES.USER.FROM_START(uid);
				break;
			case 'bookmark':
				if (!uid) reject('no uid received');
				q = last_item ? QUERIES.ISSUES.BOOKMARKED.START_AFTER(uid, last_item) : QUERIES.ISSUES.BOOKMARKED.FROM_START(uid);
				break;
			default:
				break;
		}
		console.debug(`[query] ${flag} issues`);
		try {
			getDocs(q).then((docSnap) => {
				if (!docSnap.docs || !docSnap.docs.length === 0) return;
				const data = docSnap.docs.map((doc) => {
					return {id: doc.id, ...doc.data()};
				});
				const last_item = docSnap.docs[docSnap.docs.length - 1];
				resolve({data, last: last_item, message: `fetching is successful, last item is ${last_item}`});
			});
		} catch (e) {
			reject(e);
		}
	});
};

const fetchComments = async (issue_id) => {
	const q = QUERIES.COMMENTS(issue_id);
	console.debug('[query]', q.path);
	const snapshot = await getDocs(q);
	const result = snapshot.docs
		.map((d) => {
			const data = d.data();
			return {id: d.id, ...data};
		})
		.sort((a, b) => a.created_at - b.created_at);
	return result;
};

const newComment = async (issue_id, user_id, data) => {
	if (!issue_id || !user_id || !data) throw new Error('issue, user, data가 없습니다', 'issue:', issue_id, 'user:', user_id, 'data:', data);
	const commentDocRef = doc(db, DB_KEY.DB.ISSUES, issue_id, 'comments', `${user_id}-${Date.now()}`);
	const issueRef = doc(db, DB_KEY.DB.ISSUES, issue_id);
	try {
		await runTransaction(db, async (transaction) => {
			console.debug('[transaction]', commentDocRef.path, issueRef.path);
			const commentDoc = await transaction.get(commentDocRef);
			const issueDoc = await transaction.get(issueRef);
			if (!commentDoc.exists()) transaction.set(commentDocRef, data);
			const newCount = issueDoc.data().comments_count ? issueDoc.data().comments_count + 1 : 1;
			transaction.update(issueRef, {comments_count: newCount});
			console.debug('[transaction:done]', commentDocRef.path, issueRef.path);
		});
	} catch (e) {
		console.error('[transaction: failed]', e);
	}
};

const getProfileDownloadURL = (url) => {
	return new Promise((resolve, reject) => {
		console.debug('[firestore: read] getDownloadURL');
		getDownloadURL(ref(storage, url)).then((downloadUrl) => {
			resolve(downloadUrl);
		});
	});
};

const Vote = async (user, issue, pick) => {
	if (!user) {
		throw new Error('로그인 후에 사용할 수 있습니다.');
		// fireAlert('', ALERTS.ERROR);
	}
	try {
		await runTransaction(db, async (transaction) => {
			const issueRef = doc(db, DB_KEY.DB.ISSUES, issue.id);
			const userVoteRef = doc(db, DB_KEY.DB.USERVOTE, user.id);
			const user_vote = {[issue.id]: pick};
			const issueDoc = await transaction.get(issueRef);
			const userVoteDoc = await transaction.get(userVoteRef);
			if (!issueDoc.exists()) throw new Error({message: `issueDoc is not exist, ref: ${issueRef}`});
			if (!userVoteDoc.exists()) transaction.set(userVoteRef, user_vote);
			if (userVoteDoc.exists() && Object.keys(userVoteDoc.data()).includes(issue.id)) throw new Error(`this user already voted to this question`);
			const issue_vote = issueDoc.data().choices[pick].vote + 1;
			transaction.update(issueRef, {[`choices.${pick}.vote`]: issue_vote});
			transaction.update(userVoteRef, user_vote);
			console.debug('[transaction:done]', issueRef.path, userVoteRef.path);
		});
	} catch (e) {
		console.error('transaction failed: ', e);
	}
};

const toggleBookmark = async (user, issue) => {
	if (!user) {
		throw new Error('로그인 후에 사용할 수 있습니다.');
	}
	try {
		await runTransaction(db, async (transaction) => {
			const issueRef = doc(db, DB_KEY.DB.ISSUES, issue.id);
			const issueDoc = await transaction.get(issueRef);
			if (!issueDoc.exists()) throw new Error({message: `issueDoc is not exist, ref: ${issueRef}`});
			const bus = issueDoc.data()[DB_KEY.ISSUE.BOOKMARK] ? issueDoc.data()[DB_KEY.ISSUE.BOOKMARK] : [];
			const nbus = bus.includes(user.id) ? bus.filter((u) => u !== user.id) : [...bus, user.id];
			transaction.update(issueRef, {[DB_KEY.ISSUE.BOOKMARK]: nbus});
			console.debug('[transaction]', issueRef.path);
		});
	} catch (e) {
		console.error(e);
	}
};

export {QUERIES, api};
