import styled from 'styled-components';
import {useState} from 'react';
import {AiOutlineClose} from 'react-icons/ai';
import {Colors, ALERTS} from 'constant';
import {Button} from 'component';
import {api} from 'apis';

const choicesTemplate = {
	default: {
		form: [{text: '', vote: 0}, {text: '', vote: 0}]
	}
};

function NewIssue({user, fireAlert, refreshIssues}) {
	const MAX_CHOICES_COUNT = 5;
	const [isQuestionFocused, setIsQuestionFocused] = useState(false);
	const [question, setQuestion] = useState('');
	const [choices, setChoices] = useState(choicesTemplate.default.form);

	const resetIssue = () => {
		setQuestion('');
		setChoices(choicesTemplate.default.form);
		setIsQuestionFocused(false);
	};

	const onQuestionChange = (e) => {
		setQuestion(e.target.value);
	};

	const handleOptionTextChange = (e, key) => {
		const {value} = e.target;
		const res = choices.map((choice, i) => (i === key ? {...choice, text: value} : choice));
		setChoices(res);
	};

	const handleOptionDelete = (idx) => {
		const res = choices.filter((_, i) => i !== idx);
		setChoices(res);
	};

	const onAddOptionClick = () => {
		setChoices([...choices, {text: '', vote: 0}]);
	};

	const handleIssueSubmit = async () => {
		if (!user) {
			fireAlert('로그인 후에 질문을 올릴 수 있어요', ALERTS.ERROR);
			return;
		}
		if (question === '' || question === null || question === undefined) {
			fireAlert('질문을 입력해주세요', ALERTS.ERROR);
			return;
		} else if (choices.length < 2) {
			fireAlert('최소 두개 이상의 선택지를 작성해 주세요.', ALERTS.ERROR);
			return;
		} else {
			if (choices.find((c) => c.text === '')) {
				fireAlert('비어있는 선택지가 있어요!', ALERTS.ERROR);
				return;
			}
			// Add a new document with a generated id.
			const data = {
				author: user.id,
				question,
				choices: choices.reduce((acc, cur, i) => {
					return {...acc, [i]: {...cur}};
				}, []),
				created_at: Date.now(),
				upvote: 0,
				downvote: 0,
				comments_count: 0
			};
			const docRef = await api.issue.new(data);
			console.debug('[addDoc]', docRef.path);

			resetIssue();
			if (refreshIssues) refreshIssues();
		}
	};
	return (
		<StyledWrapper focused={isQuestionFocused}>
			<StyledInputQuestion
				focused={isQuestionFocused}
				onFocus={() => setIsQuestionFocused(true)}
				onChange={onQuestionChange}
				value={question}
				type='text'
				name='question'
				required
				placeholder='사람들의 생각이 궁금한가요?'
			/>
			{isQuestionFocused && (
				<>
					<ScrollArea>
						<div>
							{choices.map((choice, i) => {
								return (
									<div key={i} style={{position: 'relative'}}>
										<StyledInputChoice name={i} onChange={(e) => handleOptionTextChange(e, i)} type='text' value={choice.text} placeholder={`선택지 ${Number(i) + 1}`} />
										<Button style={{position: 'absolute', right: 4}} transparent onClick={() => handleOptionDelete(i)}>
											<AiOutlineClose fill='#9f9da7' />
										</Button>
									</div>
								);
							})}
							{Object.values(choices).length < MAX_CHOICES_COUNT && <StyledBtnAddOption onClick={onAddOptionClick}>선택지 추가</StyledBtnAddOption>}
						</div>
					</ScrollArea>

					<Button primary onClick={handleIssueSubmit} style={{width: '100%'}}>
						업로드
					</Button>
				</>
			)}
		</StyledWrapper>
	);
}

const StyledWrapper = styled.div`
	background: ${(props) => (props.focused ? 'white' : 'transparent')};
	margin-bottom: ${(props) => (props.focused ? '20px' : '0')};
	padding: 1rem;
	box-shadow: ${(props) => (props.focused ? '0px 5px 7px #e9e9e9' : 'none')};
`;

const ScrollArea = styled.div`
	overflow-y: scroll;
	margin: 0.5em 0;
	flex: 1;
`;

const StyledInputQuestion = styled.textarea`
	width: 100%;
	border: none;
	font-size: 1.2rem;
	padding: 0.5em;
	box-sizing: border-box;
	height: ${(props) => (props.focused ? '100px' : '2em')};
	border-radius: 0.5rem;
	background-color: #eae9ef;
	// margin-bottom: 0.5em;
`;

const StyledInputChoice = styled.input`
	box-sizing: border-box;
	width: 100%;
	font-size: 1rem;
	padding: 0.7em;
	margin-bottom: 0.3em;
	border-radius: 0.5rem;
	border: none;
	background-color: #eae9ef;
`;

const StyledBtnAddOption = styled.button`
	border: none;
	background: none;
	color: ${Colors.highlight};
	width: 100%;
`;
export default NewIssue;
