let data = {};

const setUserPhotoURL = (id, downloadURL) => {
	data = {...data, [id]: downloadURL};
};

const getUserPhotoURL = (uid) => {
	return data[uid] || null;
};

const photo = {
	get: (uid) => getUserPhotoURL(uid),
	set: (uid, url) => setUserPhotoURL(uid, url)
};

export default photo;
