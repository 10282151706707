import {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {MdSend} from 'react-icons/md';
import Colors from 'constant/colors';
import Comment from './comment';
import {api} from 'apis';

function Comments({user, issue, style, refreshIssue}) {
	const [comments, setComments] = useState(null);

	const getComments = useCallback(async () => {
		const comments = await api.comments(issue.id);
		setComments(comments);
	}, [issue.id]);

	useEffect(() => {
		getComments();
	}, [getComments]);

	return (
		<Container style={style}>
			{comments && comments.map((c) => <Comment key={c.id} comment={c} />)}
			{user ? <NewComment user={user} issue={issue} getComments={getComments} refreshIssue={refreshIssue} /> : <LoginRequired>로그인 후에 댓글을 달 수 있어요</LoginRequired>}
		</Container>
	);
}

function NewComment({user, issue, getComments, refreshIssue}) {
	const [commentText, setCommentText] = useState('');

	const onInputChange = (e) => {
		setCommentText(e.target.value);
	};

	const handleCommentCommit = async () => {
		if (!user) {
			console.error('no user');
			return;
		}
		if (!commentText) return;
		const data = {
			created_at: Date.now(),
			author: user,
			text: commentText
		};

		api.comment.new(issue.id, user.id, data).then(() => {
			getComments();
			setCommentText('');
			refreshIssue(issue.id);
		});
	};

	const disabled = !commentText;

	return (
		<StyledInputWrapper>
			<StyledInput name='comment' type='text' placeholder='새 댓글 작성하기' onChange={onInputChange} value={commentText} />
			<StyledSubmit onClick={handleCommentCommit} disabled={disabled}>
				<MdSend />
			</StyledSubmit>
		</StyledInputWrapper>
	);
}
export default Comments;

const Container = styled.div`
	background-color: #eae9ef;
	padding: 0.5rem;
	margin: 0.5rem;
	border-radius: 0.5rem;
`;

const StyledInputWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	border-radius: 0.5rem;
	border: 1px solid ${Colors.highlight};
	overflow: hidden;
	margin-top: 1em;
`;
const StyledInput = styled.textarea`
	flex: 1;
	border: none;
	padding: 0.5em;
`;
const StyledSubmit = styled.button`
	border: none;
	background-color: ${(props) => (props.disabled ? '#8d8d8d' : Colors.highlight)};
	color: white;
	font-size: 1.2rem;
`;

const LoginRequired = styled.div`
	background-color: ${Colors.info};
	border-radius: 0.4rem;
	color: white;
	padding: 0.5rem;
	text-align: center;
`;

Comments.propTypes = {
	refreshIssue: PropTypes.func.isRequired
};

NewComment.propTypes = {
	refreshIssue: PropTypes.func.isRequired
};
