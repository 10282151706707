import styled from 'styled-components';
import {useState, useEffect} from 'react';
import {auth, auth_google} from 'fbase';
import {actionCodeSettings, SIGNIN_METHOD, EMAIL_PROCESS} from 'fbase';
import {
	signInWithEmailAndPassword,
	signInWithPopup,
	GoogleAuthProvider,
	sendSignInLinkToEmail,
	fetchSignInMethodsForEmail,
	EmailAuthProvider,
	isSignInWithEmailLink,
	signInWithEmailLink
} from 'firebase/auth';
import {Button} from 'component';
import {Flex} from 'component/layout';
import {ALERTS} from 'constant';

function LoginPage({fireAlert}) {
	const [emailProcess, setEmailProcess] = useState(EMAIL_PROCESS.WAITING_FOR_INPUT);
	const [loginMethod, setLoginMethod] = useState(null);
	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);

	const onInputChange = (e) => {
		const {name, value} = e.target;
		switch (name) {
			case 'email':
				setEmail(value);
				if (emailProcess !== EMAIL_PROCESS.WAITING_FOR_INPUT) {
					setEmailProcess(EMAIL_PROCESS.WAITING_FOR_INPUT);
				}
				if (loginMethod !== null) {
					setLoginMethod(null);
				}
				break;
			case 'password':
				setPassword(value);
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		if (isSignInWithEmailLink(auth, window.location.href)) {
			let email = window.localStorage.getItem('emailForSignIn');
			if (!email) window.prompt('가입하신 이메일을 적어주세요');
			signInWithEmailLink(auth, email, window.location.href)
				.then((result) => {
					console.log(result);
					window.localStorage.removeItem('emailForSignIn');
					fireAlert('로그인 성공');
				})
				.catch((error) => {
					fireAlert(error.message);
				});
		}
	});

	const onSignInWithEmailAndPassword = () => {
		if (!email || !password) return;
		signInWithEmailAndPassword(auth, email, password)
			.then((userCredential) => {
				console.debug('[signInWithEmailAndPassword]', userCredential);
			})
			.catch((error) => {
				fireAlert(error.message, ALERTS.ERROR);
			});
	};

	const onContinueWithEmail = () => {
		if (!email) {
			fireAlert('이메일을 입력해 주세요', ALERTS.ERROR);
			return;
		}
		fetchSignInMethodsForEmail(auth, email)
			.then((signInMethods) => {
				// ['emailLink', 'password', 'google.com']
				const isNewUser = signInMethods.length === 0;
				if (isNewUser || signInMethods.indexOf(EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD) !== -1) {
					if (emailProcess !== EMAIL_PROCESS.WAITING_FOR_INPUT) return;
					setLoginMethod(SIGNIN_METHOD.EMAIL_LINK);
					setEmailProcess(EMAIL_PROCESS.SENDING_EMAIL_VERIFICATION);
					sendSignInLinkToEmail(auth, email, actionCodeSettings)
						.then(() => {
							window.localStorage.setItem('emailForSignIn', email);
							setEmailProcess(EMAIL_PROCESS.EMAIL_SENT);
						})
						.catch((error) => fireAlert(error.message));
				} else if (signInMethods.indexOf(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) !== -1) {
					setLoginMethod(SIGNIN_METHOD.PASSWORD);
				} else if (signInMethods.indexOf(SIGNIN_METHOD.GOOGLE) !== -1) {
					setLoginMethod(SIGNIN_METHOD.GOOGLE);
					fireAlert('동일한 이메일의 구글 계정이 가입되어 있습니다. [Continue with Google]로 계속 진행해 주세요');
				}
			})
			.catch((error) => fireAlert(error.message, ALERTS.ERROR));
	};

	const onSignInWithGoogle = () => {
		signInWithPopup(auth, auth_google)
			.then((result) => {
				const credential = GoogleAuthProvider.credentialFromResult(result);
				const token = credential.accessToken;
				const user = result.user;
				console.debug('[onSignInWithGoogle]', token, user);
			})
			.catch((error) => {
				const {code, message, email} = error;
				const credential = GoogleAuthProvider.credentialFromError(error);
				console.error('onSignInWithGoogle', code, message, email, credential);
			});
	};

	return (
		<Container>
			<Button onClick={onSignInWithGoogle} full pill primary transparent>
				Continue with Google
			</Button>

			<HorizontalDivider />
			<InputContainer>
				<StyledInput onChange={onInputChange} name='email' type='text' placeholder='email' />
			</InputContainer>

			{loginMethod === SIGNIN_METHOD.PASSWORD ? (
				<>
					<InputContainer>
						<StyledInput onChange={onInputChange} name='password' type='password' placeholder='password' />
					</InputContainer>
					<Button primary full pill onClick={onSignInWithEmailAndPassword}>
						확인
					</Button>
					<div>비밀번호를 잊으셨나요?(준비중)</div>
				</>
			) : (
				<Button primary full pill onClick={onContinueWithEmail}>
					{emailProcess === EMAIL_PROCESS.WAITING_FOR_INPUT && '이메일로 계속하기'}
					{emailProcess === EMAIL_PROCESS.SENDING_EMAIL_VERIFICATION && '로딩 중...'}
					{emailProcess === EMAIL_PROCESS.EMAIL_SENT && '메일함에서 인증메일을 확인해 주세요!'}
				</Button>
			)}
		</Container>
	);
}

export default LoginPage;

const HorizontalDivider = () => {
	return (
		<Flex align='center' style={{width: '90%', padding: '1em', marginBottom: '1em'}}>
			<div style={{flex: 1, borderBottom: '1px solid #ada8a8'}}></div>
			<div style={{margin: '0 1em', color: '#ada8a8'}}>or</div>
			<div style={{flex: 1, borderBottom: '1px solid #ada8a8'}}></div>
		</Flex>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: 'center;
	height: 85%;
	padding: 2rem;
`;

const StyledInput = styled.input`
	border: none;
	width: 95%;
	background: none;
`;

const InputContainer = styled.div`
	display: flex;
	width: 100%;
	height: 3em;
	padding: 0.5em 1em;
	background-color: white;
	border-radius: 2em;
	margin-bottom: 1em;
	box-sizing: border-box;
`;
