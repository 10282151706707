import styled from 'styled-components';
import {useEffect, useState, useRef, useCallback} from 'react';
import {Issue, NoContent, Loading} from 'component';
import {api} from 'apis';

function MyIssuePage({uid, user, myvotes, fireAlert}) {
	const lastItemRef = useRef(null);
	const nextPageTriggerRef = useRef(null);
	const [loaded, setLoaded] = useState(false);
	const [isLastPage, setIsLastPage] = useState(false);
	const [issues, setIssues] = useState(null);

	const init = useCallback(async () => {
		setLoaded(false);
		try {
			const {data, last} = await api.issues.author(uid);
			if (data && data.length > 0) {
				setIssues(data);
				lastItemRef.current = last;
			} else {
				setIsLastPage(true);
			}
		} catch (e) {
			console.error(e);
		}
		setLoaded(true);
	}, [uid]);

	useEffect(() => {
		init();
	}, [init]);

	const refreshIssue = async (id) => {
		try {
			const result = await api.issue.get(id);
			const new_issues = issues.map((i) => {
				return i.id === id ? result : i;
			});
			setIssues(new_issues);
		} catch (e) {
			console.error(e);
		}
	};
	const handleObserver = useCallback(
		async (entry) => {
			const target = entry[0];
			if (target.isIntersecting) {
				const {data, last} = await api.issues.author(uid, lastItemRef.current);
				if (data.length === 0 && last === undefined) {
					setIsLastPage(true);
				} else {
					if (issues) {
						setIssues([...issues, ...data]);
					} else {
						setIssues(data);
					}
					lastItemRef.current = last;
				}
			}
		},
		[issues, uid]
	);

	useEffect(() => {
		const options = {root: null, rootMargin: '0px', treshold: 1.0};
		const observer = new IntersectionObserver(handleObserver, options);
		if (nextPageTriggerRef.current) observer.observe(nextPageTriggerRef.current);
		return () => observer.disconnect();
	}, [handleObserver, loaded]);

	return loaded ? (
		issues ? (
			<>
				{issues.map((issue) => (
					<Issue
						key={issue.id}
						user={user}
						issue={issue}
						isAuthor={user ? issue.author === user.id : false}
						mypick={myvotes && myvotes[issue.id] ? myvotes[issue.id] : null}
						fireAlert={fireAlert}
						refreshIssue={refreshIssue}
					/>
				))}
				{!isLastPage && <StyledShowMore ref={nextPageTriggerRef}>더보기</StyledShowMore>}
			</>
		) : (
			<NoContent />
		)
	) : (
		<Loading />
	);
}
const StyledShowMore = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export default MyIssuePage;
