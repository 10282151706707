import {useState} from 'react';
import {Routes, Route, Link, useParams} from 'react-router-dom';
import styled from 'styled-components';
import {ProfileImg, Button} from 'component';
import {ALERTS, Photos} from 'constant';
import {auth, storage} from 'fbase';
import {ref, uploadString} from 'firebase/storage';
import {deleteUser, updateProfile} from 'firebase/auth';
import {Popup, Flex} from 'component/layout';
import {AiFillEdit} from 'react-icons/ai';
import {api} from 'apis';
import MyIssuePage from './myIssues';
import MyBookmarkPage from './myBookmark';

function ProfilePage({user, alert, fireAlert, myvotes, refreshUser}) {
	let params = useParams();
	const uid = params.uid;
	const [isNicknameEditmode, setIsNicknameEditmode] = useState(false);
	const [newNickname, setNewNickname] = useState(user.displayName);
	const [isPhotoEditMode, setIsPhotoEditMode] = useState(false);
	const [attatchment, setAttatchment] = useState(null);
	const [isDeleteAccountPrompt, setIsDeleteAccountPrompt] = useState(false);

	const updateFirebaseProfile = async () => {
		if (!auth.currentUser) return;
		if (newNickname === user.displayName) return;
		if (newNickname.length > 13) {
			fireAlert('닉네임이... 너무 길어요...!', ALERTS.ERROR);
			return;
		}
		await updateProfile(auth.currentUser, {displayName: newNickname});
		refreshUser();
		setIsNicknameEditmode(false);
	};

	const onEditNicknameTry = () => {
		setIsNicknameEditmode(true);
	};

	const onNewNicknameChange = (e) => {
		setNewNickname(e.target.value);
	};

	const onEditNicknameCancel = () => {
		setIsNicknameEditmode(false);
		setNewNickname(user.displayName);
	};

	const onEditPhotoTry = () => {
		setIsPhotoEditMode(true);
	};

	const onEditPhotoCancel = () => {
		setIsPhotoEditMode(false);
		setAttatchment(null);
	};

	const onProfilePhotoChange = (e) => {
		const file = e.target.files[0];
		if (file.size > 1000000) {
			fireAlert('사진의 용량이 너무 커요!', ALERTS.ERROR);
		} else {
			const reader = new FileReader();
			reader.onloadend = (finishedEvt) => {
				setAttatchment(finishedEvt.currentTarget.result);
				console.log('[finisehdevt]', finishedEvt.currentTarget);
			};
			reader.readAsDataURL(file);
		}
	};

	const onProfilePhotoConfirm = () => {
		if (!attatchment) return;
		const path = `profileImg/${user.id}`;
		const imgRef = ref(storage, `profileImg/${user.id}`);
		uploadString(imgRef, attatchment, 'data_url').then(() => {
			updateProfile(auth.currentUser, {photoURL: path}).then(() => {
				api.profile.image_download_url(path).then((url) => {
					Photos.set(user.id, url);
					refreshUser({photoURL: url});
				});
				fireAlert('프로필 사진 교체 완료!');
				setIsPhotoEditMode(false);
				setAttatchment(null);
			});
		});
	};

	const onDeleteAccountTry = () => {
		setIsDeleteAccountPrompt(true);
	};

	const onDeleteAccountCancel = () => {
		setIsDeleteAccountPrompt(false);
	};

	const onDeleteAccountConfirm = () => {
		deleteUser(auth.currentUser)
			.then(() => {
				fireAlert('계정이 삭제되었습니다. 만나서 반가웠어요', ALERTS.INFO);
			})
			.catch((error) => {
				if (error.code === 'auth/requires-recent-login') {
					fireAlert('재로그인 후에 진행해 주세요.', ALERTS.ERROR);
				} else {
					fireAlert(`문제가 발생했습니다. 고객센터에 문의해 주세요. ${error.code}`, ALERTS.ERROR);
				}
			});
	};

	return (
		<>
			<Container>
				{isDeleteAccountPrompt && (
					<Popup handleClose={onDeleteAccountCancel}>
						<Flex center style={{flex: 1}}>
							<p style={{whiteSpace: 'break-spaces', lineHeight: 1.4, fontWeight: 500, textAlign: 'center'}}>
								{`정말로 계정을 삭제하시겠습니까?\n계정 보안을 위해 최신 로그인 정보를 필요로 합니다.\n로그아웃 후에 새롭게 로그인 해 주시고, '확인' 버튼을 눌러 탈퇴를 진행해 주세요.\n한번 실행한 뒤에는 되돌릴 수 없습니다.`}
							</p>
						</Flex>
						<Flex space='evenly'>
							<Button onClick={onDeleteAccountCancel} style={{flex: 1}}>
								취소
							</Button>
							<Button warning onClick={onDeleteAccountConfirm} style={{flex: 1, marginLeft: 10}}>
								확인
							</Button>
						</Flex>
					</Popup>
				)}
				{isPhotoEditMode && (
					<Popup handleClose={onEditPhotoCancel}>
						<Flex column space='between' style={{height: '100%'}}>
							<Flex flex={1} column center style={{overflow: 'hidden'}}>
								{attatchment ? (
									<img src={attatchment} style={{width: '100%'}} alt='이미지 불러오기 실패' />
								) : (
									<div style={{fontWeight: 600, fontSize: '0.8rem'}}>
										<input type='file' accept='image/*' style={{marginBottom: 10}} onChange={onProfilePhotoChange} />
										<div>정사각형 이미지일수록 예쁘게 나와요</div>
										<div>업로드 용량: 최대 1MB</div>
									</div>
								)}
							</Flex>
							<Flex space='evenly'>
								<Button onClick={onEditPhotoCancel} style={{flex: 1}}>
									취소
								</Button>
								<Button onClick={onProfilePhotoConfirm} primary style={{flex: 1, marginLeft: 10}}>
									업로드
								</Button>
							</Flex>
						</Flex>
					</Popup>
				)}
				<Flex align='center'>
					<ProfileImg onClick={onEditPhotoTry} user={user} editable width='5rem' height='5rem' imgurl={user.photoURL} style={{marginRight: 20}} />
					<div style={{flex: 1}}>
						<StyledNickname>
							{isNicknameEditmode ? (
								<Flex align='center'>
									<input type='text' value={newNickname} onChange={onNewNicknameChange} />
									<Button transparent inline onClick={updateFirebaseProfile}>
										변경
									</Button>
									<Button warning transparent inline onClick={onEditNicknameCancel}>
										취소
									</Button>
								</Flex>
							) : (
								<Flex>
									{user.displayName}{' '}
									<StyledEditButton onClick={onEditNicknameTry}>
										<AiFillEdit />
									</StyledEditButton>
								</Flex>
							)}
						</StyledNickname>
						<StyledEmail>{user.email}</StyledEmail>
						<Button warning onClick={onDeleteAccountTry} style={{padding: '0.4em'}}>
							계정 삭제
						</Button>
					</div>
				</Flex>
			</Container>
			<>
				<SectionTitles>
					<StyledSectionTitle>
						<Link to=''>작성한 글</Link>
					</StyledSectionTitle>
					<StyledSectionTitle>
						<Link to='bookmark'>저장한 글</Link>
					</StyledSectionTitle>
				</SectionTitles>
				<Routes>
					<Route path='/' element={<MyIssuePage uid={uid} user={user} myvotes={myvotes} fireAlert={fireAlert} />} />
					<Route path='/bookmark' element={<MyBookmarkPage uid={uid} user={user} myvotes={myvotes} fireAlert={fireAlert} />} />
				</Routes>
			</>
		</>
	);
}

export default ProfilePage;

const Container = styled.div`
	background-color: white;
	padding: 1em;
`;

const SectionTitles = styled.div`
	display: flex;
`;

const StyledNickname = styled.div`
	font-weight: 500;
	font-size: 1.2rem;
`;

const StyledSectionTitle = styled.h3`
	margin-left: 1em;
`;

const StyledEmail = styled.div`
	margin-bottom: 0.5rem;
`;

const StyledEditButton = styled.button`
	border: none;
	background: none;
`;
