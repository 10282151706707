import styled from 'styled-components';
import {Colors} from 'constant';
import {CryingKnock} from 'constant/svg';
function NoContent() {
	return (
		<Container>
			<Logo>
				<CryingKnock style={{fill: Colors.deemed}} />
			</Logo>
			<h3>작성한 글이 없어요</h3>
		</Container>
	);
}

const Logo = styled.div`
	width: 50%;
`;

const Container = styled.div`
	height: 60vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: ${Colors.deemed};
`;

export default NoContent;
