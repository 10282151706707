import styled from 'styled-components';

function Loading() {
	return (
		<Container>
			<div>Loading...</div>
		</Container>
	);
}

export default Loading;

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
