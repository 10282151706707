import styled from 'styled-components';
import {ALERTS, Colors, Depth} from 'constant';

function Alert({alert}) {
	const type = alert.type;
	return <Container type={type}>{alert.message}</Container>;
}

const Container = styled.div`
	position: fixed;
	bottom: 0;
	right: 10px;
	left: 10px;
	bottom: 10px;
	margin: auto;
	color: white;
	background-color: ${({type}) => (type === ALERTS.WARNING ? 'gold' : type === ALERTS.INFO ? Colors.info : type === ALERTS.ERROR ? Colors.warning : 'grey')};
	z-index: ${Depth.alert};
	padding: 1rem;
	border-radius: 5px;
	max-width: 640px;
	font-weight: bold;
`;

export default Alert;
