// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app';
import {getAnalytics} from 'firebase/analytics';
import {getAuth} from 'firebase/auth';
import {getFirestore} from 'firebase/firestore';
import {getStorage} from 'firebase/storage';
import {FacebookAuthProvider} from 'firebase/auth';
import {GoogleAuthProvider} from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const actionCodeSettings = {
	// URL you want to redirect back to. The domain (www.example.com) for this
	// URL must be in the authorized domains list in the Firebase Console.
	url: `${process.env.REACT_APP_HOST}/login`,
	// This must be true.
	handleCodeInApp: true
};

const SIGNIN_METHOD = {
	GOOGLE: 'google.com',
	PASSWORD: 'password',
	EMAIL_LINK: 'emailLink'
};

const EMAIL_PROCESS = {
	WAITING_FOR_INPUT: 0,
	SENDING_EMAIL_VERIFICATION: 1,
	EMAIL_SENT: 2
};

const DB_KEY = {
	DB: {
		ISSUES: 'issues',
		USERVOTE: 'user-vote'
	},
	ISSUE: {
		BOOKMARK: 'bookmarked_users'
	}
};

const PAGINATION_COUNT = 20;

// Initialize Firebase
const fbase = initializeApp(firebaseConfig);
// const analytics = getAnalytics(fbase);
const auth = getAuth();
const db = getFirestore();
const storage = getStorage();
const auth_facebook = new FacebookAuthProvider();
const auth_google = new GoogleAuthProvider();
const analytics = getAnalytics(fbase);

export {fbase, auth, db, auth_facebook, auth_google, analytics, actionCodeSettings, SIGNIN_METHOD, EMAIL_PROCESS, storage, DB_KEY, PAGINATION_COUNT};
