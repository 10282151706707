import styled from 'styled-components';
import {useState, useEffect} from 'react';
import {FaUserCircle} from 'react-icons/fa';
import {api} from 'apis';
import {Photos} from 'constant';

function ProfileImg({user, width, height, onClick, imgurl, style, editable}) {
	const [img, setImg] = useState(null);
	const {id: uid, photoURL} = user;
	useEffect(() => {
		if (Photos.get(uid)) {
			setImg(Photos.get(uid));
		} else if (!photoURL) {
		} else if (photoURL.startsWith('gs://') || photoURL.startsWith('profileImg')) {
			api.profile.image_download_url(photoURL).then((downloadUrl) => {
				Photos.set(uid, downloadUrl);
				setImg(downloadUrl);
			});
		} else {
			setImg(photoURL);
		}
	}, [photoURL, uid]);

	return (
		<StyledWrapper onClick={onClick} width={width} height={height} style={style}>
			{img ? (
				<StyledImg
					src={img}
					referrerpolicy='no-referrer'
					onError={() => {
						setImg(null);
					}}
				/>
			) : (
				<FaUserCircle style={{width: '100%', height: '100%', color: '#acabb3'}} />
			)}
			{editable && <StyledEditBtn>편집</StyledEditBtn>}
		</StyledWrapper>
	);
}

export default ProfileImg;

const StyledImg = styled.img`
	width: 100%;
	height: 100%;
`;

const StyledWrapper = styled.div`
	position: relative;
	width: ${(props) => (props.width ? props.width : '2rem')};
	height: ${(props) => (props.height ? props.height : '2rem')};
	border-radius: 10rem;
	overflow: hidden;
`;

const StyledEditBtn = styled.div`
	position: absolute;
	bottom: 0;
	width: 95%;
	text-align: center;
	background: rgba(0, 0, 0, 0.5);
	color: white;
	font-size: 0.9rem;
	padding: 0.1rem;
	font-weight: 400;
`;
