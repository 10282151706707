import styled from 'styled-components';
function Flex({children, space, align, center, column, flex, style}) {
	return (
		<Container space={space} align={align} center={center} column={column} flex={flex} style={style}>
			{children}
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	${(props) => props.flex && `flex: ${props.flex};`}
  ${(props) => props.column && `flex-direction: column;`}
	${(props) => props.space && `justify-content: space-${props.space}`}
  ${(props) => props.align && `align-items: ${props.align}`}
  ${(props) => props.center && `justify-content: center; align-items: center;`}
  
`;

export default Flex;
