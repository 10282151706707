import styled from 'styled-components';
import {AiOutlineClose} from 'react-icons/ai';
// import {Button} from 'component';
import {Depth} from 'constant';

function Popup({handleClose, children, style}) {
	const onClick = (e) => {
		e.stopPropagation();
	};

	return (
		<Wrapper onClick={handleClose} style={style}>
			<XIcon>
				<AiOutlineClose fill='white' size={30} />
			</XIcon>
			<Container onClick={onClick}>{children}</Container>
		</Wrapper>
	);
}

export default Popup;

const Wrapper = styled.div`
	position: fixed;
	z-index: ${Depth.popup};
	background-color: rgba(0, 0, 0, 0.5);
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
`;

const Container = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	margin: auto;
	display: flex;
	flex-direction: column;
	width: 80%;
	height: 50%;
	min-height: 300px;
	background-color: white;
	border-radius: 1em;
	padding: 1em;
`;

const XIcon = styled.div`
	position: absolute;
	top: 10px;
	right: 10px;
	background: none;
	font-size: 1.2em;
`;
