import styled from 'styled-components';
import Colors from 'constant/colors';

const Button = ({style, onClick, full, pill, children, inline, primary, transparent, warning}) => {
	return (
		<Container style={{...style}} pill={pill} inline={inline} full={full} onClick={onClick} primary={primary} transparent={transparent} warning={warning}>
			{children}
		</Container>
	);
};

const Container = styled.button`
	font-weight: 600;
	font-size: 0.9rem;
	border: none;
	padding: ${(props) => !props.inline && '1em'};
	${(props) => props.full && 'width: 100%'};
	border-radius: ${(props) => (props.pill ? '100rem' : '0.5rem')};
	${(props) =>
		props.primary &&
		`background-color: ${Colors.primary};
		border: 1px solid ${Colors.primary};
		color: white;
		`}
		${(props) =>
			props.warning &&
			`background-color: ${Colors.warning};
			border: 1px solid ${Colors.warning};
			color: white;
			`}
	${(props) =>
		props.transparent &&
		`background: none;
		color: ${props.primary ? Colors.primary : props.warning ? Colors.warning : 'inherit'};
	`}
`;

export default Button;
