import styled from 'styled-components';
import {ProfileImg} from 'component';
function Comment({comment}) {
	const {author, text, created_at} = comment;
	const {photoURL, displayName} = author;
	return (
		<Container>
			<Author>
				<ProfileImg user={author} imgurl={photoURL} style={{width: '1.5rem', height: '1.5rem', marginRight: '5px'}} />
				<AuthorName>{displayName}</AuthorName>
				<CreatedAt>{created_at && new Date(created_at).toLocaleString()}</CreatedAt>
			</Author>
			<div>{text}</div>
		</Container>
	);
}

const Container = styled.div`
	margin: 0.5rem;
`;

const Author = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 0.4rem;
`;

const AuthorName = styled.div`
	font-weight: bold;
	font-size: 0.9rem;
	margin-right: 5px;
`;

const CreatedAt = styled.span`
	color: grey;
	font-size: 0.7em;
`;

export default Comment;
