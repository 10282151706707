import styled from 'styled-components';
import {SleepyKnock} from 'constant/svg';
import {Colors} from 'constant';

function NotFound() {
	return (
		<Container>
			<Logo>
				<SleepyKnock style={{fill: Colors.highlight}} />
			</Logo>
			<h2>404 NOT FOUND</h2>
			<div>
				저런! 존재하지 않는 <span style={{textDecoration: 'line-through'}}>여자친구</span>페이지 입니다
			</div>
		</Container>
	);
}

export default NotFound;

const Logo = styled.div`
	width: 50%;
	max-width: 320px;
`;

const Container = styled.div`
	width: 100vw;
	height: 80vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: ${Colors.deemed};
`;
