import {useEffect, useState, useCallback} from 'react';
import {useParams} from 'react-router';
import {Issue, Loading} from 'component';
import {api} from 'apis';
import {ErrorPage} from 'pages';
import {Helmet} from 'react-helmet';

function IssueDetail({user, fireAlert}) {
	const {issueId} = useParams();
	const [loaded, setLoaded] = useState(false);
	const [issue, setIssue] = useState(null);

	const getIssue = useCallback(async () => {
		try {
			const data = await api.issue.get(issueId);
			setIssue(data);
			console.log(data);
		} catch (e) {
			console.error(e);
		}
	}, [issueId]);

	useEffect(() => {
		setLoaded(false);
		getIssue().then(() => {
			setLoaded(true);
		});
	}, [getIssue]);

	const refreshIssue = () => {
		getIssue();
	};

	return (
		<div>
			<Helmet>
				<title>{loaded && issue && issue.question}</title>
				<meta property='og:title' content='당신의 생각을 들려주세요' />
				<meta property='og:description' content={issue && issue.question} />
			</Helmet>
			{loaded ? issue ? <Issue issue={issue} user={user} fireAlert={fireAlert} spreadComments refreshIssue={refreshIssue} /> : <ErrorPage message='주소를 잘 못 찾아오신 것 같다' /> : <Loading />}
		</div>
	);
}

export default IssueDetail;
